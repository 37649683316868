import { Expose } from 'class-transformer';
import { IsUrl } from 'class-validator';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserEnvConfig {
  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_API' })
  readonly toolkitApi!: string;

  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_VUE_APP' })
  readonly vueApp!: string;

  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_DJANGO_API' })
  readonly djangoApi!: string;

  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_GEO_API' })
  readonly geoApi!: string;

  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_ENROLLMENT_API' })
  readonly enrollmentApi!: string;

  @IsUrl({ require_tld: false })
  @Expose({ name: 'TOOLKIT_LEADS_API' })
  readonly leadsApi!: string;
}
